import React from "react"
import { Query } from "react-apollo"
import { Loader } from "@foris/foris-ui"
import { BreadCrumb } from "../../../../../../common"
import { DEFAULT_MESSAGES } from "../../../../../../common/config"
import { withForecastContext, ForecastContextInterface } from "../../../../context"
import ListPage from "./ListPage"
import { GET_UNITS_BY_KIND } from "./queries"

const breadCrumbItems = [
  { content: "Editor de demanda", link: true, to: "/forecast/demand-editor" },
  { content: "Edición de listas cruzadas", link: false }
]

const Container = (props: any) => {
  return (
    <section className="main-section forecast">
      <div className="hero-body">
        <div className="container is-fluid">
          <BreadCrumb items={breadCrumbItems} />
          <div className="columns">
            <div className="column is-three-quarters">
              <div className="is-content has-background-white">
                <div className="content">{props.content}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const CLEditorContainer = ({ forecastContext }: { forecastContext?: ForecastContextInterface }) =>
  forecastContext && (
    <Query
      query={GET_UNITS_BY_KIND}
      variables={{ projectId: forecastContext.projectId, kind: "CROSSLIST" }}
    >
      {({ loading, error, data }: any) => {
        if (loading) return <Container content={<Loader active />} />
        if (error || data.status.code !== 200)
          return <Container content={<p>{DEFAULT_MESSAGES.ERROR}</p>} />
        return <Container content={<ListPage {...{ list: data.project.unitsByKind }} />} />
      }}
    </Query>
  )

export default withForecastContext(CLEditorContainer)
