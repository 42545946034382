import gql from "graphql-tag"

export const GET_FILTERS_DATA = gql`
  query GET_FILTERS_DATA($projectId: ID!) {
    project(projectId: $projectId) {
      id
      departments {
        id
        code
        name
      }
      programs {
        id
        code
        name
        department {
          id
        }
      }
      unisabanaDepartments {
        id
        code
        name
      }
    }
    status {
      code
      msg
    }
  }
`

export const GET_DEPARTMENTS = gql`
  query GET_DEPARTMENTS($projectId: ID!) {
    project(projectId: $projectId) {
      id
      departments {
        id
        code
        name
      }
    }
  }
`

export const GET_UNITS = gql`
  query GET_UNITS($projectId: ID!, $filter: ViewFilter) {
    project(projectId: $projectId) {
      id
      departments {
        id
        code
        name
      }
      programs {
        id
        code
        name
        department {
          id
        }
      }
      unisabanaDepartments {
        id
        code
        name
      }
      dashboard {
        levelView(filter: $filter) {
          level
          curriculum {
            id
            name
            program {
              id
              name
            }
          }
          unit {
            id
            kind
            label
            progress
            totalDemand
            checkedCount
            programName
            curriculumName
            tags
            nextUnit
            previusUnit
          }
        }
        subjectView(filter: $filter) {
          subject
          unit {
            id
            kind
            label
            progress
            totalDemand
            checkedCount
            programName
            curriculumName
            tags
            nextUnit
            previusUnit
          }
        }
        crosslistView(filter: $filter) {
          unit {
            id
            kind
            label
            description
            progress
            totalDemand
            checkedCount
            programName
            curriculumName
            tags
            nextUnit
            previusUnit
          }
        }
      }
    }
    status {
      code
      msg
    }
  }
`

export const UNITS_SUBSCRIPTION = gql`
  subscription onUnitsUpdate($projectId: ID!) {
    unitUpdated(projectId: $projectId) {
      id
      kind
      label
      progress
      totalDemand
      checkedCount
      programName
      curriculumName
      tags
      nextUnit
      previusUnit
      demands {
        id
        course {
          id
          code
          name
        }
        value
        adjusted
        checked
        own
        courseCode
        courseLabel
        programName
        curriculumName
      }
    }
  }
`

export const UPDATE_DEPARTMENT_FILTER_CONTROL = gql`
  mutation UpdateDepartmentFilterControl($filterValue: FilterControl!) {
    updateDepartmentFilterControl(filterValue: $filterValue) @client
  }
`

export const UPDATE_PROGRAM_FILTER_CONTROL = gql`
  mutation UpdateProgramFilterControl($filterValue: FilterControl!) {
    updateProgramFilterControl(filterValue: $filterValue) @client
  }
`

export const UPDATE_CURRENT_FILTERS = gql`
  mutation UpdateCurrentFilters($departmentId: String, $programId: String) {
    updateCurrentFilters(departmentId: $departmentId, programId: $programId) @client
  }
`

export const FILTER_CONTROL_QUERY = gql`
  query FiltersControlQuery {
    departmentFilterControl @client {
      value
      label
      filterName
    }
    programFilterControl @client {
      value
      label
      filterName
    }
    currentFilters @client {
      departmentId
      programId
    }
  }
`
