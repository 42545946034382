import gql from "graphql-tag"

export const GET_PROJECTS_QUERY = gql`
  query GET_PROJECTS_QUERY($institutionId: ID!) {
    projects(institutionId: $institutionId) {
      id
      title
      description
      active
      state
      institutionId
      startDate
      endDate
      access {
        workspaceName
        workspaceId
        scenarioName
        scenarioId
        originName
        originId
      }
    }
  }
`

export const GET_PROJECT_BY_ID_QUERY = gql`
  query GET_PROJECT_BY_ID_QUERY($projectId: ID!) {
    project(projectId: $projectId) {
      id
      title
      description
      active
      state
      startDate
      endDate
      access {
        workspaceName
        workspaceId
        scenarioName
        scenarioId
        originName
        originId
      }
    }
  }
`

export const GET_WORKSPACES_QUERY = gql`
  query GET_WORKSPACES_QUERY {
    workspaces {
      id
      code
      name
    }
  }
`

export const GET_SCENARIOS_QUERY = gql`
  query GET_SCENARIOS_QUERY($workspaceId: ID!) {
    scenarios(workspaceId: $workspaceId) {
      id
      name
      description
    }
  }
`

export const GET_ORIGINS_QUERY = gql`
  query GET_ORIGINS_QUERY($scenarioId: ID!) {
    origins(scenarioId: $scenarioId) {
      id
      label
      demandCount
    }
  }
`

export const ADD_PROJECT_MUTATION = gql`
  mutation ADD_PROJECT_MUTATION($projectInput: ProjectInput!) {
    addProject(projectInput: $projectInput) {
      id
      active
    }
  }
`

export const CREATE_NEW_PROJECT_MUTATION = gql`
  mutation CREATE_NEW_PROJECT($projectInput: ProjectInput!) {
    addProject(projectInput: $projectInput) {
      status {
        code
        msg
      }
      project {
        id
        title
        description
        active
        state
      }
    }
  }
`

export const EDIT_PROJECT_MUTATION = gql`
  mutation EDIT_PROJECT($projectId: ID!, $projectInput: ProjectInput!) {
    editProject(projectId: $projectId, projectInput: $projectInput) {
      id
      title
      description
      active
      startDate
      endDate
      institutionId
      access {
        workspaceName
        workspaceId
        scenarioName
        scenarioId
        originName
        originId
      }
    }
  }
`

export const UPDATE_PROJECT_STATUS = gql`
  mutation CHANGE_PROJECT_STATUS($projectId: ID!, $status: Boolean) {
    changeProjectStatus(projectId: $projectId, status: $status) {
      id
      description
      active
      state
    }
  }
`

// SYNC_PROJECT_DATA

export const SYNC_PROJECT_DATA = gql`
  mutation SYNC_PROJECT_DATA($projectId: ID!) {
    syncOriginData(projectId: $projectId) {
      status {
        code
        msg
      }
      project {
        id
        title
        description
        active
        state
      }
    }
  }
`

export const EXPORT_PROJECT_DATA = gql`
  mutation EXPORT_PROJECT_DATA($projectId: ID!) {
    exportDemands(projectId: $projectId) {
      status {
        code
        msg
      }
      project {
        id
        title
        description
        active
        state
      }
    }
  }
`
