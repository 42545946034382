import React, { Component, Fragment } from "react"
import { withApollo, WithApolloClient } from "react-apollo"
import { Button, Form, Message } from "@foris/foris-ui"
import { DepartmentsFilter, ProgramsFilter, SchoolDepartmentFilter } from "./index"
import { IFilterOptions } from "../models"
import { GET_UNITS, UNITS_SUBSCRIPTION } from "../queries"
import { ForecastContextConsumer } from "../../../../../context"

type FiltersProps = WithApolloClient<IFilterOptions & any>

class Filters extends Component<FiltersProps> {
  state = {
    value: null,
    filterCombination: { wasSent: false, isValid: true, message: "" },
    departmentHasEmptyError: false,
    programHasEmptyError: false
  }

  componentDidMount() {
    this.setState({
      value: {
        project: this.props.project
      }
    })
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const context = this.props.context
    if (prevProps !== this.props) {
      if (context.dashboard.currentDepartmentListSelected.value) {
        this.setState({ departmentHasEmptyError: false })
      }
      if (context.dashboard.currentProgramListSelected.value) {
        this.setState({ programHasEmptyError: false })
      }
    }
  }

  async searchUnits(e: any, department: any, program: any, departmentSchool: any) {
    e.preventDefault()

    if (
      // SIN FILTROS SELECCIONADOS
      (!department.value && !program.value && !departmentSchool.value) ||
      // SÓLO CON ESCUELA Y SIN OTROS FILTROS SELECCIONADOS
      (department.value && (!program.value && !departmentSchool.value))
    ) {
      this.setState({
        filterCombination: {
          wasSent: true,
          isValid: false,
          message: "Selecciona escuela y al menos una de las opciones: programa y departamento."
        }
      })
      return false
    }

    this.setState({ filterCombination: { wasSent: true, isValid: true } })

    let filters: any = {
      departmentId: department ? department.value : null,
      programId: program.value === "*" ? null : program.value,
      unisabanaDepartmentId: departmentSchool.value === "*" ? null : departmentSchool.value
    }

    const result = await this.props.client.query({
      query: GET_UNITS,
      variables: {
        projectId: this.props.projectId,
        filter: filters
      }
    })

    return result.data.project.dashboard

    /* .catch((error: any) => {
      console.log("there was an error sending the query", error)
      this.setState({ loadingResults: false })
    }) */
  }

  render() {
    return (
      <Fragment>
        <ForecastContextConsumer>
          {context => {
            if (!context) return <p>error...</p>
            const { departmentHasEmptyError, programHasEmptyError, filterCombination } = this.state
            return (
              <Fragment>
                <div className="row">
                  <div className="col">
                    <div
                      style={{ padding: "2em 3em", backgroundColor: "white", marginBottom: "2em" }}
                    >
                      <Form>
                        <Form.Group className="row" style={{ marginBottom: "1em" }}>
                          <Form.Field className="col-md-4">
                            <label>Escuela</label>
                            <DepartmentsFilter
                              error={departmentHasEmptyError}
                              project={this.props.project}
                              context={context}
                            />
                          </Form.Field>
                          <Form.Field className="col-md-4">
                            <label>Programa</label>
                            <ProgramsFilter
                              error={programHasEmptyError}
                              project={this.props.project}
                              context={context}
                            />
                          </Form.Field>
                          <Form.Field className="col-md-4">
                            <label>Departamento</label>
                            <SchoolDepartmentFilter
                              error={false}
                              project={this.props.project}
                              context={context}
                            />
                          </Form.Field>
                        </Form.Group>

                        <Button
                          primary
                          loading={context.dashboard.loadingSearch}
                          onClick={async (e: React.SyntheticEvent) => {
                            context.updateDashboardCurrentData("loadingSearch", true)
                            const result = await this.searchUnits(
                              e,
                              context.dashboard.currentDepartmentListSelected,
                              context.dashboard.currentProgramListSelected,
                              context.dashboard.currentSchoolDepartmentListSelected
                            )
                            context.updateDashboardCurrentData("unitView", result)
                            context.updateDashboardCurrentData("loadingSearch", false)
                            context.updateDashboardCurrentData("filterSearchState", "TOUCHED")
                          }}
                        >
                          Buscar
                        </Button>

                        <Message
                          visible={filterCombination.wasSent && filterCombination.isValid === false}
                          warning
                        >
                          <Message.Header>
                            Debes seleccionar más opciones de búsqueda
                          </Message.Header>
                          <p>{filterCombination.message}</p>
                        </Message>
                      </Form>
                    </div>
                  </div>
                </div>
              </Fragment>
            )
          }}
        </ForecastContextConsumer>
      </Fragment>
    )
  }
}

export default withApollo(Filters)
