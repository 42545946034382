import React, { Component } from "react"
import { Select } from "@foris/foris-ui"

const filterFactory = (array: object[]) => {
  return array.map((d: any) => ({
    value: d.id,
    key: d.id + d.name,
    text: d.name
  }))
}
export class DepartmentsFilter extends Component<any, any> {
  render() {
    return (
      <Select
        search
        className="ellipsisText"
        placeholder="Selecciona una escuela"
        noResultsMessage={"No se han encontrado resultados"}
        options={filterFactory(this.props.project.departments)}
        error={this.props.error}
        defaultValue={this.props.context.dashboard.currentDepartmentListSelected.value}
        onChange={(e: React.SyntheticEvent, filterValue: any) => {
          let newProgramList
          newProgramList = this.props.project.programs.filter((item: any) => {
            return item.department.id === filterValue.value
          })

          this.props.context.updateDashboardCurrentData("currentDepartmentListSelected", {
            selectedValue: filterValue,
            newProgramList: newProgramList
          })
        }}
      />
    )
  }
}

export const ProgramsFilter: React.FC<{ error: any; context: any; project: any }> = (
  props: any
) => {
  const rawOptions = props.context.dashboard.currentProgramList
  const options: any = filterFactory(rawOptions)

  if (options[0] && options[0].key && options[0].key !== "*__program") {
    options.unshift({ value: "*", key: "*__program", text: "Todos los programas" })
  } else if (options.length === 0) {
    options.push({ value: "*", key: "*__program", text: "Todos los programas" })
  }

  return (
    <Select
      search
      placeholder="Selecciona un programa"
      noResultsMessage={"No se han encontrado resultados"}
      options={options}
      error={props.error}
      className="ellipsisText"
      disabled={!props.context.dashboard.currentDepartmentListSelected.value}
      value={props.context.dashboard.currentProgramListSelected.value || "*"}
      onChange={(e: React.SyntheticEvent, filterValue: any) => {
        props.context.updateDashboardCurrentData("currentProgramListSelected", filterValue)
      }}
    />
  )
}

export const SchoolDepartmentFilter: React.FC<{ error: any; context: any; project: any }> = (
  props: any
) => {
  const rawOptions = props.project.unisabanaDepartments
  const options: any = filterFactory(rawOptions)

  if (options[0].key !== "*__department") {
    options.unshift({ value: "*", key: "*__department", text: "Todos los departamentos" })
  }

  if (!props.context.dashboard.currentSchoolDepartmentListSelected.value) {
    props.context.updateDashboardCurrentData("currentSchoolDepartmentListSelected", {
      value: "*",
      key: "*__department",
      text: "Todos los departamentos"
    })
  }

  return (
    <Select
      search
      placeholder="Selecciona un departamento"
      noResultsMessage={"No se han encontrado resultados"}
      options={options}
      className="ellipsisText"
      error={props.error}
      value={props.context.dashboard.currentSchoolDepartmentListSelected.value}
      onChange={(e: React.SyntheticEvent, filterValue: any) => {
        props.context.updateDashboardCurrentData("currentSchoolDepartmentListSelected", filterValue)
      }}
    />
  )
}
