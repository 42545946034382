import Dashboard from "./modules/demand-editor/pages/dashboard/DashboardContainer"
import Editor from "./modules/demand-editor/pages/editor/EditorContainer"
import CLEditor from "./modules/demand-editor/pages/crosslist-editor/CLEditorContainer"
import AddPageContainer from "./modules/demand-editor/pages/crosslist-editor/AddPageContainer"
import EditPageContainer from "./modules/demand-editor/pages/crosslist-editor/EditPageContainer"
import AddProjectPageContainer from "./modules/demand-editor/pages/project/AddProjectPageContainer"
import ProjectListContainer from "./modules/demand-editor/pages/project/ProjectListContainer"
import EditProjectPageContainer from "./modules/demand-editor/pages/project/EditProjectPageContainer"
import ProjectStatus from "./modules/demand-editor/pages/project-status"

export const base = "/forecast"

export const moduleRoutes = [
  {
    path: `${base}/demand-editor/project-status`,
    component: ProjectStatus,
    name: "forecast@demand-editor/projectStatus",
    permissions: "public"
  },
  {
    path: `${base}/demand-editor/crosslists/edit/:unitId`,
    component: EditPageContainer,
    name: "forecast@demand-editor/cleditor/edit",
    permissions: "onlyAdmin"
  },
  {
    path: `${base}/demand-editor/crosslists/add`,
    component: AddPageContainer,
    name: "forecast@demand-editor/cleditor/add",
    permissions: "onlyAdmin"
  },
  {
    path: `${base}/demand-editor/crosslists`,
    component: CLEditor,
    name: "forecast@demand-editor/cleditor",
    permissions: "onlyAdmin"
  },
  {
    path: `${base}/demand-editor/project/add`,
    component: AddProjectPageContainer,
    name: "forecast@demand-editor/project/add",
    permissions: "onlyAdmin"
  },
  {
    path: `${base}/demand-editor/project/edit/:projectId`,
    component: EditProjectPageContainer,
    name: "forecast@demand-editor/project/edit/:projectId",
    permissions: "onlyAdmin"
  },
  {
    path: `${base}/demand-editor/projects`,
    component: ProjectListContainer,
    name: "forecast@demand-editor/project",
    permissions: "onlyAdmin"
  },
  {
    path: `${base}/demand-editor/unit/:unitId`,
    component: Editor,
    name: "forecast@demand-editor/editor",
    permissions: "public"
  },
  {
    path: `${base}/demand-editor`,
    component: Dashboard,
    name: "forecast@demand-editor",
    permissions: "public"
  },
  {
    path: base,
    name: "forecast@home",
    permissions: "public"
  }
]
