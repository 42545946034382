import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import { Query, withApollo, WithApolloClient } from "react-apollo"
import gql from "graphql-tag"
import { withRouter } from "react-router-dom"
import styled from "styled-components"
import { BreadCrumb } from "../../../../../../common"
import { DEFAULT_MESSAGES } from "../../../../../../common/config"
import { ForecastContextConsumer } from "../../../../context"
import { TableWithMutation } from "./components"
import { GET_UNIT_DETAIL } from "./queries"
import { Loader, Icon } from "@foris/foris-ui"

const UnitNavWrapper = styled.div`
  float: right;
  padding: 1.4em 0.5em 0 0em;
  a > i.icon {
    color: white;
    font-size: 2em;
  }
  a > i.icon:hover {
    color: #d6d5d5;
  }
`

const breadCrumbItems = [
  { content: "Editor de demanda", link: true, to: "/forecast/demand-editor" },
  { content: "Editor", link: false }
]

const getTitle = (kind: string, label: string) => {
  let title
  switch (kind) {
    case "LEVEL":
      title = `${label}`
      break
    case "SUBJECT":
      title = `${label}`
      break
    case "CROSSLIST":
      title = `Lista cruzada: ${label}`
      break
  }
  return title
}

const Container = (props: any) => {
  return (
    <div className="row">
      <div className="col">
        <BreadCrumb items={breadCrumbItems} />
        {/* <h3 style={{ margin: "0px 0px 1.5em 0px", padding: "0px" }}>
          Test-41 <span style={{ fontWeight: 400 }}>(BASE / UPCH / Origen: 525)</span>
        </h3> */}
        <div className="info" style={{ backgroundColor: "white" }}>
          <div style={{ backgroundColor: "#4486c3" }}>
            <h1
              style={{
                fontWeight: 500,
                color: "white",
                fontSize: "16px",
                display: "inline-block",
                margin: "0px",
                padding: "1.1em"
              }}
            >
              {props.viewTitle}
            </h1>
            {props.unitType === "CROSSLIST" &&
            props.auth.role.permissions.crosslist.includes("update") ? (
              <div style={{ float: "right" }}>
                <Link to={`/forecast/demand-editor/crosslists/edit/${props.unitID}`}>
                  <Icon
                    style={{ fontSize: "1.3em", padding: ".9em 1.7em 0 0", color: "white" }}
                    name="edit outline"
                  />
                </Link>
              </div>
            ) : (
              <UnitNavWrapper>
                {props.prevLink && (
                  <Link
                    to={`/forecast/demand-editor/unit/${props.prevLink}`}
                    style={{ marginRight: "5px" }}
                  >
                    <Icon name="arrow alternate circle left outline" />
                  </Link>
                )}
                {props.nextLink && (
                  <Link to={`/forecast/demand-editor/unit/${props.nextLink}`}>
                    <Icon name="arrow alternate circle right outline" />
                  </Link>
                )}
              </UnitNavWrapper>
            )}
          </div>
          <div className="content" style={{ paddingTop: 0 }}>
            {props.content}
          </div>
        </div>
      </div>
    </div>
  )
}

type EditorContainerProps = WithApolloClient<{} & any>

class EditorContainer extends Component<EditorContainerProps, any> {
  state = {
    title: null,
    previusUnit: null,
    nextUnit: null,
    unitType: null
  }

  componentWillMount() {
    const todo = this.props.client.readFragment({
      id: this.props.match.params.unitId,
      fragment: gql`
        fragment unitLabel on Unit {
          kind
          label
          tags
          programName
          curriculumName
        }
      `
    })

    if (todo && todo.kind) this.updateUnitType(todo.kind)

    // TITLES
    if ((todo && todo.label) || (todo && todo.kind === "LEVEL")) {
      let title
      title = todo.label
      if (todo.kind === "LEVEL") {
        title = `${todo.programName} | ${todo.curriculumName} | Nivel: ${todo.tags.level}`
      }
      if (todo.kind === "SUBJECT") {
        title = `${todo.tags.subject} ${todo.label}`
      }
      this.setState({ title: getTitle(todo.kind, title) })
    }
  }

  updateTitle = (kind: string, label: string) => {
    this.setState({ title: getTitle(kind, label) })
  }

  updateNavLinks = (prevId: string | null, nextId: string | null) => {
    this.setState({ previusUnit: prevId, nextUnit: nextId })
  }

  updateUnitType = (type: string) => {
    this.setState({ unitType: type })
  }

  render() {
    const unitID = this.props.match.params.unitId
    const { title, previusUnit, nextUnit, unitType } = this.state
    return (
      <ForecastContextConsumer>
        {context =>
          context && (
            <Container
              viewTitle={title}
              prevLink={previusUnit}
              nextLink={nextUnit}
              unitType={unitType}
              unitID={unitID}
              auth={context.auth}
              content={
                <Query
                  query={GET_UNIT_DETAIL}
                  variables={{ projectId: context.projectId, unitId: unitID }}
                >
                  {({ loading, error, data }: any) => {
                    if (loading)
                      return (
                        <div style={{ minHeight: "400px", lineHeight: "3em" }}>
                          <div style={{ display: "block", paddingTop: "150px" }}>
                            <Loader active inline="centered" content="Cargando..." />
                          </div>
                        </div>
                      )
                    if (error || data.status.code !== 200) return <p>{DEFAULT_MESSAGES.ERROR}</p>
                    return (
                      <TableWithMutation
                        {...data}
                        context={context}
                        updateTitle={this.updateTitle}
                        updateNavLinks={this.updateNavLinks}
                        updateUnitType={this.updateUnitType}
                      />
                    )
                  }}
                </Query>
              }
            />
          )
        }
      </ForecastContextConsumer>
    )
  }
}

export default withRouter(withApollo(EditorContainer))
