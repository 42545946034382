import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import * as serviceWorker from "./serviceWorker"
import { ApolloProvider } from "react-apollo"
import App from "./App"
import client from "./apolloClient"

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
)

serviceWorker.unregister()
