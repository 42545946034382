import React, { Component, Fragment } from "react"
import styled from "styled-components"
import { Table, Input, Divider } from "@foris/foris-ui"
import { groupBy } from "lodash"
import LevelItem from "./LevelItem"
import SubjectItem from "./SubjectItem"
import CrossListItem from "./CrossListItem"
import { ILevel, ISubject, ICrossList } from "../models"

export class LevelView extends Component<{ items: ILevel[] | any }, {}> {
  state = {
    levels: this.props.items || null
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props !== prevProps) {
      this.setState({ levels: this.props.items })
    }
  }

  render() {
    const { levels } = this.state

    const groupedLevels: any = groupBy(levels, function(level: any) {
      return level.curriculum.name
    })
    return (
      <div className="row">
        <div className="col">
          {groupedLevels && Object.keys(groupedLevels).length ? (
            <>
              {Object.keys(groupedLevels).map((level: string, groupIndex: number) => (
                <Fragment key={level}>
                  <h4 style={{ fontWeight: 400 }}>{`${
                    groupedLevels[level][0].curriculum.program.name
                  } - ${level}`}</h4>
                  <div className="row">
                    {groupedLevels[level].map((item: ILevel) => {
                      if (item.unit) {
                        return <LevelItem key={item.unit.id} {...item} />
                      }
                    })}
                  </div>
                  {Object.keys(groupedLevels).length !== groupIndex + 1 && <Divider section />}
                </Fragment>
              ))}
            </>
          ) : (
            <p>No hay resultados para mostrar</p>
          )}
        </div>
      </div>
    )
  }
}

const TableWrapper = styled.div`
  min-height: 400px;
  .ui.table > tbody > tr > td,
  .ui.table > tr > td {
    padding: 0.8em 0.9em;
  }
  .ui[class*="very basic"].table:not(.sortable):not(.striped) > tbody > tr > td:last-child {
    padding-right: 0.9em;
  }
  .ui[class*="very basic"].table:not(.sortable):not(.striped) > tbody > tr > td:first-child {
    padding-left: 0.9em;
  }
`
export class SubjectView extends Component<{ items: ISubject[] | any }, {}> {
  state = {
    source: []
  }

  componentDidMount() {
    this.setState({ source: this.props.items })
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props !== prevProps) {
      this.setState({ source: this.props.items })
    }
  }

  filterList = (event: any) => {
    if (event.target.value === "") this.setState({ source: this.props.items })

    const q: string = event.target.value.toLowerCase()
    let updatedList: any = []

    this.props.items.filter(
      (item: any): any => {
        if (
          item.unit.label.toLowerCase().search(q) !== -1 ||
          item.subject.toLowerCase().search(q) !== -1
        ) {
          updatedList.push(item)
        }
      }
    )

    this.setState({ source: updatedList })
  }

  render() {
    const { source } = this.state
    return (
      <div className="row">
        <div className="col">
          {this.props.items.length > 0 && (
            <div style={{ margin: "1em 0 2.5em 0" }}>
              <Input
                size="small"
                icon="search"
                placeholder="Filtrar..."
                onChange={this.filterList}
              />
            </div>
          )}
          <TableWrapper>
            {source && source.length > 0 ? (
              <Table style={{ width: "100%" }} basic="very" celled selectable collapsing>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ width: "153px" }}>Progreso</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "100px", paddingLeft: "11px" }}>
                      Alias
                    </Table.HeaderCell>
                    <Table.HeaderCell>Asignatura</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {source.map((item: any) => {
                    if (item.unit) {
                      return <SubjectItem key={item.unit.id} {...item} />
                    }
                  })}
                </Table.Body>
              </Table>
            ) : (
              <p>No hay resultados para mostrar</p>
            )}
          </TableWrapper>
        </div>
      </div>
    )
  }
}

export class CrosslistView extends Component<{ items: ICrossList[] | any }, {}> {
  state = {
    source: []
  }

  componentDidMount() {
    this.setState({ source: this.props.items })
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props !== prevProps) {
      this.setState({ source: this.props.items })
    }
  }

  filterList = (event: any) => {
    if (event.target.value === "") this.setState({ source: this.props.items })

    const q: string = event.target.value.toLowerCase()
    let updatedList: any = []

    this.props.items.filter(
      (item: any): any => {
        if (item.unit.label.toLowerCase().search(q) !== -1) {
          updatedList.push(item)
        }
      }
    )

    this.setState({ source: updatedList })
  }

  render() {
    const { source } = this.state
    return (
      <div className="row">
        <div className="col">
          {this.props.items.length > 0 && (
            <div style={{ margin: "1em 0 2.5em 0" }}>
              <Input
                size="small"
                icon="search"
                placeholder="Filtrar..."
                onChange={this.filterList}
              />
            </div>
          )}
          <TableWrapper>
            {source && source.length ? (
              <Table style={{ width: "100%" }} basic="very" celled selectable collapsing>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ width: "153px" }}>Progreso</Table.HeaderCell>
                    <Table.HeaderCell style={{ width: "100px", paddingLeft: "11px" }}>
                      Código
                    </Table.HeaderCell>
                    <Table.HeaderCell>Nombre</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {source.map((item: any) => {
                    if (item.unit) {
                      return <CrossListItem key={item.unit.id} {...item} />
                    }
                  })}
                </Table.Body>
              </Table>
            ) : (
              <p>No hay resultados para mostrar</p>
            )}
          </TableWrapper>
        </div>
      </div>
    )
  }
}
