import React from "react"
import { NavLink } from "react-router-dom"
import { Breadcrumb } from "@foris/foris-ui"

import styled from "styled-components"

const BreadCrumbWrapper = styled.div`
  .ui.breadcrumb .active.section {
    font-weight: 400;
  }
`

type BreadCrumbItem = {
  content: string
  link: boolean
  to?: string
}

interface IBreadCrumbProps {
  items: BreadCrumbItem[]
}

const NavBar = (props: any) => {
  let content: any = []
  props.nav.map((item: any, i: number) => {
    let bread
    if (item.link) {
      bread = (
        <Breadcrumb.Section key={`item__${i}`} as={NavLink} to={item.to}>
          {item.content} {i + 1 !== props.nav.length && <Breadcrumb.Divider icon="right chevron" />}
        </Breadcrumb.Section>
      )
    } else {
      bread = (
        <Breadcrumb.Section key={`item__${i}`}>
          {item.content} {i + 1 !== props.nav.length && <Breadcrumb.Divider icon="right chevron" />}
        </Breadcrumb.Section>
      )
    }

    content.push(bread)
  })

  return (
    <BreadCrumbWrapper>
      <Breadcrumb> {content} </Breadcrumb>
    </BreadCrumbWrapper>
  )
}

const BreadCrumb: React.FC<IBreadCrumbProps> = props => {
  const items: BreadCrumbItem[] = props.items
  return (
    <div style={{ marginBottom: "0.5em" }}>
      <NavBar nav={items} />
    </div>
  )
}

export default BreadCrumb
