import React, { Component } from "react"
import { Query, withApollo, WithApolloClient } from "react-apollo"
import { Loader, Message } from "@foris/foris-ui"
import styled from "styled-components"
import { groupBy } from "lodash"
import { ForecastContextInterface } from "../../../../../context"
import { LevelView, SubjectView, CrosslistView } from "./index"
import { GET_UNITS, UNITS_SUBSCRIPTION } from "../queries"

const TabContainer = styled.div`
  margin-botom: 80px;
`
export class SubscriptionWrapper extends Component<any, any> {
  render() {
    return this.props.children
  }
}

type UnitsResultPropsWithApollo = WithApolloClient<ForecastContextInterface & any>
export class UnitsResult extends Component<UnitsResultPropsWithApollo, any> {
  subscription: any = null

  componentDidMount() {
    this.subscription = this.props.client
      .subscribe({
        query: UNITS_SUBSCRIPTION,
        variables: {
          projectId: this.props.projectId
        }
      })
      .subscribe({
        next: (response: any) => {
          if (this.props.dashboard.unitView) {
            const unitView: any = this.props.dashboard.unitView
            switch (response.data.unitUpdated.kind) {
              case "LEVEL":
                unitView["levelView"].map((item: any) => {
                  if (item.unit.id === response.data.unitUpdated.id) {
                    item.unit.progress = response.data.unitUpdated.progress
                  }
                })
                break
              case "SUBJECT":
                unitView["subjectView"].map((item: any) => {
                  if (item.unit.id === response.data.unitUpdated.id) {
                    item.unit.progress = response.data.unitUpdated.progress
                  }
                })
                break
              case "CROSSLIST":
                unitView["crosslistView"].map((item: any) => {
                  if (item.unit.id === response.data.unitUpdated.id) {
                    item.unit.progress = response.data.unitUpdated.progress
                  }
                })
                break
            }

            this.props.updateDashboardCurrentData("unitView", unitView)
            this.forceUpdate()
          }
        },
        error(err: any) {
          console.error("err", err)
        }
      })
  }

  componentWillUnmount() {
    this.subscription.unsubscribe()
  }

  displayUnits(selectedTab: any) {
    const groupedLevels: any = groupBy(this.props.dashboard.unitView.levelView, function(
      level: any
    ) {
      return level.curriculum.name
    })

    return (
      <TabContainer>
        <div className="ui attached tabular menu">
          <a
            className={`${selectedTab === "curriculum" ? "item active " : "item "} ${
              Object.keys(groupedLevels).length === 0 ? "disabled" : ""
            }`}
            onClick={(e: React.SyntheticEvent) =>
              this.props.updateDashboardCurrentData("selectedTab", "curriculum")
            }
          >
            Currículos ({Object.keys(groupedLevels).length})
          </a>
          <a
            className={`${selectedTab === "subjects" ? "item active " : "item "} ${
              this.props.dashboard.unitView.subjectView.length === 0 ? "disabled" : ""
            }`}
            onClick={(e: React.SyntheticEvent) =>
              this.props.updateDashboardCurrentData("selectedTab", "subjects")
            }
          >
            Asignaturas ({this.props.dashboard.unitView.subjectView.length})
          </a>
          <a
            className={`${selectedTab === "crosslists" ? "item active " : "item "} ${
              this.props.dashboard.unitView.crosslistView.length === 0 ? "disabled" : ""
            }`}
            onClick={(e: React.SyntheticEvent) =>
              this.props.updateDashboardCurrentData("selectedTab", "crosslists")
            }
          >
            Listas cruzadas ({this.props.dashboard.unitView.crosslistView.length})
          </a>
        </div>
        <div
          className={
            "ui bottom attached segment " + (selectedTab === "curriculum" ? "active tab" : "tab")
          }
        >
          <div style={{ padding: "2em 2em 0 2em" }}>
            <LevelView items={this.props.dashboard.unitView.levelView} />
          </div>
        </div>
        <div
          className={
            "ui bottom attached segment " + (selectedTab === "subjects" ? "active tab" : "tab")
          }
        >
          <div style={{ padding: "2em 2em 0 2em" }}>
            <SubjectView items={this.props.dashboard.unitView.subjectView} />
          </div>
        </div>
        <div
          className={
            "ui bottom attached segment " + (selectedTab === "crosslists" ? "active tab" : "tab")
          }
        >
          <div style={{ padding: "2em 2em 0 2em" }}>
            <CrosslistView items={this.props.dashboard.unitView.crosslistView} />
          </div>
        </div>
      </TabContainer>
    )
  }

  render() {
    const parentContextHasData =
      (this.props.dashboard.unitView.crosslistView ||
        this.props.dashboard.unitView.levelView ||
        this.props.dashboard.unitView.subjectView) &&
      (this.props.dashboard.unitView.crosslistView.length ||
        this.props.dashboard.unitView.levelView.length ||
        this.props.dashboard.unitView.subjectView.length)

    if (parentContextHasData) {
      //this.subscribeToUnits(this.props.projectId, subscribeToMore)
      return this.displayUnits(this.props.dashboard.selectedTab)
    } else {
      return (
        <>
          {this.props.dashboard.filterSearchState === "TOUCHED" ? (
            <Message>
              <Message.Header>No se han encontrado resultados</Message.Header>
              <p>Por favor revise su selección de búsqueda.</p>
            </Message>
          ) : (
            <>{this.props.filterSearchState}</>
          )}
        </>
      )
    }
  }
}

export default withApollo(UnitsResult)
