import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { ForecastContextConsumer } from "../../../../context"
import { BreadCrumb } from "../../../../../../common"
import { FormCLEdit } from "./components"

const breadCrumbItems = [
  { content: "Editor de demanda", link: true, to: "/forecast/demand-editor" },
  { content: "Editar lista cruzada", link: false }
]

const Container = (props: any) => {
  return (
    <div className="row">
      <div className="col">
        <BreadCrumb items={breadCrumbItems} />
        {props.content}
      </div>
    </div>
  )
}

class EditPageContainer extends Component<any, any> {
  render() {
    const unitID = this.props.match.params.unitId
    return (
      <ForecastContextConsumer>
        {context =>
          context && (
            <Container
              content={<FormCLEdit unitId={unitID} projectId={context.projectId} {...context} />}
            />
          )
        }
      </ForecastContextConsumer>
    )
  }
}

export default withRouter(EditPageContainer)
