import React, { Component, useState } from "react"
import styled from "styled-components"
import { Query, Mutation } from "react-apollo"
import { Link } from "react-router-dom"
import {
  UPDATE_PROJECT_STATUS,
  GET_PROJECTS_QUERY,
  SYNC_PROJECT_DATA,
  EXPORT_PROJECT_DATA
} from "../queries"
import { Button, Checkbox, Icon, Table, Popup, Modal, Loader } from "@foris/foris-ui"

import { Slide, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const TOAST_OPTIONS: object = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  transition: Slide
}

const CheckboWrapper = styled.div`
  .ui.checkbox input[disabled] ~ .box:after,
  .ui.checkbox input[disabled] ~ label,
  .ui.disabled.checkbox .box:after,
  .ui.disabled.checkbox label {
    opacity: 1;
    cursor: pointer !important;
  }
`

const TableWrapper = styled.div`
  margin-bottom: 100px;
  .disabledRow {
    td:not(:first-child) {
      opacity: 0.6;
      pointer-events: none;
    }
  }
`

const OperationIconWrapper = styled.div`
  cursor: pointer;
  display: inline;
  padding: 0 0.4em;
  font-size: 1.1em;
  & > a {
    color: inherit;
  }
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`

const formatDate = (date: Date) => {
  try {
    const currentDate = date

    let day = currentDate.getDate()
    let month = currentDate.getMonth() + 1
    let year = currentDate.getFullYear()

    return `${day}.${month}.${year}`
  } catch (e) {
    return ""
  }
}

const ListPage: React.FC<{ context: any }> = ({ context }) => {
  const [openEnableConfirmation, setOpenEnableConfirmation] = useState(false)
  const [openDisableConfirmation, setOpenDisableConfirmation] = useState(false)

  const [openSyncConfirmation, setOpenSyncConfirmation] = useState(false)
  const [updateSyncLoading, setUpdateSyncLoading] = useState(false)

  const [openExportConfirmation, setOpenExportConfirmation] = useState(false)
  const [updateExportLoading, setUpdateExportLoading] = useState(false)

  const [projectIdToEdit, setProjectIdToEdit] = useState(null)
  const [updateLoading, setUpdateLoading] = useState(false)

  const handleChangeActiveProject = (e: React.SyntheticEvent, { project }: { project: any }) => {
    e.preventDefault()
    setProjectIdToEdit(project.id)
    if (project.active) {
      setOpenDisableConfirmation(true)
    } else {
      setOpenEnableConfirmation(true)
    }
  }

  return (
    <div className="row">
      <div className="col">
        <div style={{ marginBottom: "3em", display: "block" }}>
          <Link to={`/forecast/demand-editor/project/add`}>
            <Button basic icon labelPosition="left" style={{ backgroundColor: "white" }}>
              <Icon name="plus" />
              Crear proyecto
            </Button>
          </Link>
        </div>

        <TableWrapper>
          <Query
            query={GET_PROJECTS_QUERY}
            variables={{ institutionId: context.auth.institutionId }}
          >
            {({ loading, error, data }: any) => {
              if (loading)
                return (
                  <div style={{ minHeight: "200px" }}>
                    <Loader active />
                  </div>
                )
              if (error)
                return <p>Ha ocurrido un error en el servidor, favor intente nuevamente.</p>
              // return <Container content={<ListPage context={forecastContext} {...{ list: data.projects }}  />} />

              return (
                <Table celled compact definition>
                  <Table.Header fullWidth>
                    <Table.Row>
                      <Table.HeaderCell />
                      <Table.HeaderCell>Nombre</Table.HeaderCell>
                      <Table.HeaderCell>Apertura</Table.HeaderCell>
                      <Table.HeaderCell>Cierre</Table.HeaderCell>
                      <Table.HeaderCell>Ambiente</Table.HeaderCell>
                      <Table.HeaderCell>Escenario</Table.HeaderCell>
                      <Table.HeaderCell>Demanda</Table.HeaderCell>
                      <Table.HeaderCell style={{ width: "125px" }}>Acciones</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.projects.map((project: any, index: number) => (
                      <Table.Row key={index}>
                        {/* className={!project.active ? "disabledRow" : ""} */}
                        <Table.Cell collapsing>
                          <CheckboWrapper>
                            <Checkbox
                              toggle
                              checked={project.active}
                              disabled={true}
                              project={project}
                              onClick={handleChangeActiveProject}
                            />
                          </CheckboWrapper>
                        </Table.Cell>
                        <Table.Cell> {project.title} </Table.Cell>
                        <Table.Cell>
                          {formatDate(new Date(`${project.startDate}T00:00:00`))}
                        </Table.Cell>
                        <Table.Cell>
                          {formatDate(new Date(`${project.endDate}T00:00:00`))}
                        </Table.Cell>
                        <Table.Cell>{project.access.workspaceName}</Table.Cell>
                        <Table.Cell>{project.access.scenarioName}</Table.Cell>
                        <Table.Cell>{project.access.originName}</Table.Cell>
                        <Table.Cell textAlign="center">
                          <Popup
                            content="Editar proyecto"
                            trigger={
                              <OperationIconWrapper>
                                <Link to={`/forecast/demand-editor/project/edit/${project.id}`}>
                                  <Icon name="edit outline" />
                                </Link>
                              </OperationIconWrapper>
                            }
                          />
                          <Popup
                            content="Exportar proyecto"
                            trigger={
                              <OperationIconWrapper>
                                <Icon
                                  name="upload"
                                  onClick={(e: React.SyntheticEvent) => {
                                    setProjectIdToEdit(project.id)
                                    setOpenExportConfirmation(true)
                                  }}
                                />
                              </OperationIconWrapper>
                            }
                          />
                          <Popup
                            content="Sincronizar proyecto"
                            trigger={
                              <OperationIconWrapper>
                                <Icon
                                  name="sync alternate"
                                  onClick={(e: React.SyntheticEvent) => {
                                    setProjectIdToEdit(project.id)
                                    setOpenSyncConfirmation(true)
                                  }}
                                />
                              </OperationIconWrapper>
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              )
            }}
          </Query>
        </TableWrapper>
      </div>

      <Modal size="small" open={openEnableConfirmation}>
        <Modal.Header>Activar proyecto</Modal.Header>
        <Modal.Content>
          <p>Al activar este proyecto se desactivará el proyecto activo actual.</p>
        </Modal.Content>
        <Modal.Actions>
          <Mutation
            mutation={UPDATE_PROJECT_STATUS}
            onCompleted={(data: any) => {
              setUpdateLoading(false)
              const currentStorage = JSON.parse(localStorage.__darwined_user_data)
              let project = {
                id: data.changeProjectStatus.id,
                active: data.changeProjectStatus.active,
                state: data.changeProjectStatus.state,
                description: data.changeProjectStatus.description
              }
              currentStorage["project"] = project
              localStorage.setItem("__darwined_user_data", JSON.stringify(currentStorage))

              toast.info("El proyecto ha sido activado correctamente", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                transition: Slide
              })

              setTimeout(() => setOpenEnableConfirmation(false), 500)
              context.updateCurrentProject(project)
            }}
            update={(cache: any, reponse: any) => {
              try {
                const cacheObj = cache.readQuery({
                  query: GET_PROJECTS_QUERY,
                  variables: { institutionId: context.auth.institutionId }
                })
                const projects = cacheObj.projects

                const foundIndex = projects.findIndex(
                  (x: any) => x.id == reponse.data.changeProjectStatus.id
                )
                projects[foundIndex] = {
                  ...projects[foundIndex],
                  state: reponse.data.changeProjectStatus.state
                }

                projects.map((project: any) => {
                  if (project.id !== projects[foundIndex].id) {
                    project.active = false
                  }
                })

                cache.writeQuery({
                  query: GET_PROJECTS_QUERY,
                  variables: { institutionId: context.auth.institutionId },
                  data: { projects: projects }
                })
              } catch (e) {
                console.log("error: >", e)
              }
            }}
          >
            {(updateProject: any, { data }: any) => (
              <>
                <Button
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault()
                    setOpenEnableConfirmation(false)
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  primary
                  content="Activar"
                  loading={updateLoading}
                  onClick={(e: React.SyntheticEvent) => {
                    setUpdateLoading(true)
                    updateProject({ variables: { projectId: projectIdToEdit, status: true } })
                  }}
                />
              </>
            )}
          </Mutation>
        </Modal.Actions>
      </Modal>

      <Modal size="small" open={openDisableConfirmation}>
        <Modal.Header>Desactivar proyecto</Modal.Header>
        <Modal.Content>
          <p>Al desactivar este proyecto los usuarios no podrán visualizar o editar la demanda.</p>
        </Modal.Content>
        <Modal.Actions>
          <Mutation
            mutation={UPDATE_PROJECT_STATUS}
            onCompleted={(data: any) => {
              setUpdateLoading(false)
              const currentStorage = JSON.parse(localStorage.__darwined_user_data)
              let project = {
                id: data.changeProjectStatus.id,
                active: data.changeProjectStatus.active,
                state: data.changeProjectStatus.state,
                description: data.changeProjectStatus.description
              }
              currentStorage["project"] = project
              localStorage.setItem("__darwined_user_data", JSON.stringify(currentStorage))

              toast.info("El proyecto ha sido desactivado correctamente", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                transition: Slide
              })

              setTimeout(() => setOpenDisableConfirmation(false), 500)

              context.updateCurrentProject(project)
            }}
          >
            {(updateProject: any, { data }: any) => (
              <>
                <Button
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault()
                    setOpenDisableConfirmation(false)
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  primary
                  content="Desactivar"
                  loading={updateLoading}
                  onClick={(e: React.SyntheticEvent) => {
                    setUpdateLoading(true)
                    updateProject({ variables: { projectId: projectIdToEdit, status: false } })
                  }}
                />
              </>
            )}
          </Mutation>
        </Modal.Actions>
      </Modal>

      <Modal size="small" open={openExportConfirmation}>
        <Modal.Header>Exportar proyecto</Modal.Header>
        <Modal.Content>
          <p>
            Este proceso exportará la demanda ajustada de este editor al ambiente productivo de
            DarwinEd.
          </p>
          <p>No podrás realizar acciones mientras se realiza la exportación.</p>
        </Modal.Content>
        <Modal.Actions>
          <Mutation
            mutation={EXPORT_PROJECT_DATA}
            variables={{ projectId: projectIdToEdit }}
            onCompleted={(data: any) => {
              if (
                data &&
                data.exportDemands &&
                data.exportDemands.status &&
                data.exportDemands.status.code !== 200
              ) {
                switch (data.exportDemands.status.msg) {
                  case "PROJECT_EXPORTING":
                    toast.warn("Ya existe un proyecto sincronizandose actualmente.", TOAST_OPTIONS)
                    setUpdateExportLoading(false)
                    break
                }

                return false
              }

              setUpdateExportLoading(false)
              const currentStorage = JSON.parse(localStorage.__darwined_user_data)
              let project = {
                id: data.exportDemands.project.id,
                active: data.exportDemands.project.active,
                state: data.exportDemands.project.state,
                description: data.exportDemands.project.description
              }
              currentStorage["project"] = project
              localStorage.setItem("__darwined_user_data", JSON.stringify(currentStorage))

              setTimeout(() => setOpenSyncConfirmation(false), 500)
              context.updateCurrentProject(project)
            }}
          >
            {(exportProjectData: any, { data }: any) => (
              <>
                <Button
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault()
                    setOpenExportConfirmation(false)
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  primary
                  loading={updateExportLoading}
                  content="Exportar"
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault()
                    setUpdateExportLoading(true)
                    exportProjectData({ variables: { projectId: projectIdToEdit } })
                  }}
                />
              </>
            )}
          </Mutation>
        </Modal.Actions>
      </Modal>

      <Modal size="small" open={openSyncConfirmation}>
        <Modal.Header>Sincronizar proyecto</Modal.Header>
        <Modal.Content>
          <p>
            Este proceso sincronizará la demanda proyectada de DarwinEd en este ambiente de edición.
            <br />
            No podrás realizar acciones mientras se realiza la sincronización.
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Mutation
            mutation={SYNC_PROJECT_DATA}
            variables={{ projectId: projectIdToEdit }}
            onCompleted={(data: any) => {
              if (
                data &&
                data.syncOriginData &&
                data.syncOriginData.status &&
                data.syncOriginData.status.code !== 200
              ) {
                switch (data.syncOriginData.status.msg) {
                  case "PROJECT_IN_SYNC":
                    setUpdateSyncLoading(false)
                    toast.warn("Ya existe un proyecto sincronizandose actualmente.", TOAST_OPTIONS)
                    break
                }

                return false
              }

              setUpdateSyncLoading(false)
              const currentStorage = JSON.parse(localStorage.__darwined_user_data)
              let project = {
                id: data.syncOriginData.project.id,
                active: data.syncOriginData.project.active,
                state: data.syncOriginData.project.state,
                description: data.syncOriginData.project.description
              }
              currentStorage["project"] = project
              localStorage.setItem("__darwined_user_data", JSON.stringify(currentStorage))

              setTimeout(() => setOpenSyncConfirmation(false), 500)
              context.updateCurrentProject(project)
            }}
          >
            {(syncProjectData: any, { data }: any) => (
              <>
                <Button
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault()
                    setOpenSyncConfirmation(false)
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  primary
                  loading={updateSyncLoading}
                  content="Sincronizar"
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault()
                    setUpdateSyncLoading(true)
                    syncProjectData({ variables: { projectId: projectIdToEdit } })
                  }}
                />
              </>
            )}
          </Mutation>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default ListPage
