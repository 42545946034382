import React, { Component, Props } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Table, Progress } from "@foris/foris-ui"
import styled from "styled-components"
import { ISubject } from "../models"

const ProgressWrapper = styled.div`
  margin: 0px;
  .ui.small.progress {
    margin: 0px;
  }
  .ui.green.progress .bar,
  .ui.indeterminate.green.progress .bar::before,
  .ui.progress .green.bar {
    background-color: rgb(68, 157, 122);
  }
  .ui.progress {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .ui.progress .bar > .progress {
    font-weight: 400;
    font-size: 0.75em;
  }
`
interface SubjectListRouteProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
}

class SubjectLisItem extends Component<SubjectListRouteProps & ISubject> {
  render() {
    const {
      subject,
      unit: { label, id, progress }
    }: ISubject = this.props

    const handleClick = () => {
      this.props.history.push(`/forecast/demand-editor/unit/${id}`)
    }

    return (
      <Table.Row onClick={handleClick}>
        <Table.Cell>
          <ProgressWrapper>
            <Progress size="small" percent={Math.round(Number(progress) * 100)} color="green" />
          </ProgressWrapper>
        </Table.Cell>
        <Table.Cell> {subject} </Table.Cell>
        <Table.Cell> {label} </Table.Cell>
      </Table.Row>
    )
  }
}

export default withRouter(SubjectLisItem)
