import React, { Component, Props } from "react"
import { Route, Switch, RouteComponentProps } from "react-router-dom"
import DefaultLayout from "../../common/components/layout/DefaultLayout"
import { Home, CurriculumList } from "./pages"

const base = "/catalogs"

const moduleRoutes = [
  {
    path: `${base}/curriculums`,
    component: CurriculumList
  },
  {
    path: base,
    component: Home
  }
]
interface CatalogsProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
  match: any
}

type CatalogsState = {}

class Catalogs extends Component<CatalogsProps, CatalogsState> {
  render() {
    return (
      <Switch>
        <DefaultLayout>
          {moduleRoutes.map(route => (
            <Route key={route.path} path={route.path} component={route.component} />
          ))}
        </DefaultLayout>
      </Switch>
    )
  }
}

export default Catalogs
