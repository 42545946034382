import React from "react"
import { withForecastContext, ForecastContextInterface } from "../../../../context"
import { BreadCrumb } from "../../../../../../common"
import { FormCLAdd } from "./components"

const breadCrumbItems = [
  { content: "Editor de demanda", link: true, to: "/forecast/demand-editor" },
  { content: "Crear lista cruzada", link: false }
]

const Container = (props: any) => {
  return (
    <div className="row">
      <div className="col">
        <BreadCrumb items={breadCrumbItems} />
        {/* <h3 style={{ margin: "0px 0px 1.5em 0px", padding: "0px" }}>
          Test-41 <span style={{ fontWeight: 400 }}>(BASE / UPCH / Origen: 525)</span>
        </h3> */}
        {props.content}
      </div>
    </div>
  )
}

const AddPageContainer = ({ forecastContext }: { forecastContext?: ForecastContextInterface }) =>
  forecastContext && (
    <Container content={<FormCLAdd projectId={forecastContext.projectId} {...forecastContext} />} />
  )

export default withForecastContext(AddPageContainer)
