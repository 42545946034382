import React, { Component } from "react"
import { Link } from "react-router-dom"
import bioIcon from "../../../../assets/svg/icono_perfil_docente.svg"

class Instructor extends Component {
  render() {
    const instructor = {
      name: "Germán Casa"
    }
    return (
      <section className="main-section hero is-fullheight-with-navbar">
        <div className="hero-body" style={{ paddingTop: "2rem" }}>
          <div className="container is-fluid">
            <nav className="breadcrumb" aria-label="breadcrumbs">
              <ul>
                <li>
                  <Link to="/wizard">Wizard</Link>
                </li>
                <li>
                  <Link to="/wizard/instructors">Docentes</Link>
                </li>
                <li className="is-active">
                  <Link to="#" aria-current="page">
                    {instructor.name}
                  </Link>
                </li>
              </ul>
            </nav>

            <div className="columns">
              <div className="column is-three-fifths">
                <div className="info">
                  <div className="header level is-mobile">
                    <div className="level-left">
                      <h1 className="is-1 has-text-weight-semibold">Docente</h1>
                    </div>
                    <div className="level-right">
                      <button className="button is-small is-primary is-outlined is-inverted">
                        <span className="icon is-small">
                          {/* <Icon size="18" path={mdiPencil} /> */}
                        </span>
                      </button>
                    </div>
                  </div>
                  <div className="content" style={{ paddingTop: 0 }}>
                    <div className="bio">
                      <div className="bio-avatar">
                        <span className="icon is-large">
                          <img src={bioIcon} alt="instructor" />
                        </span>
                      </div>
                      <div className="bio-info">
                        <div className="bio-info-code">
                          <p>1092183-6</p>
                        </div>
                        <div className="bio-info-name">
                          <p>Germán Casas</p>
                        </div>
                      </div>
                    </div>
                    <div className="attrs">
                      <div className="attrs-item">
                        <div className="attr-name">Contrato</div>
                        <div className="attr-value">Tiempo Parcial</div>
                      </div>

                      <div className="attrs-item">
                        <div className="attr-name">Prioridad</div>
                        <div className="attr-value">25</div>
                      </div>

                      <div className="attrs-item">
                        <div className="attr-name">Bloques Mín/Máx</div>
                        <div className="attr-value">5 - 15</div>
                      </div>
                      <div className="attrs-item">
                        <div className="attr-name">Máx. Asignaturas</div>
                        <div className="attr-value">5</div>
                      </div>

                      <div className="attrs-item">
                        <div className="attr-name">Máx. Secciones</div>
                        <div className="attr-value">15</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="instructor-courses">
                  <div className="header">Asignaturas</div>
                  <div className="content">
                    <div className="course-list is-unstyled">
                      <div className="course-list-item">
                        <span className="icon is-medium eye-on">
                          {/* <Icon path={mdiEye} /> */}
                        </span>
                        <span className="icon is-medium ">
                          {/* <Icon path={mdiMenuRight} /> */}
                        </span>
                        <div className="course-info">
                          <span className="course-code">100107</span>
                          <span className="course-name">Topografía</span>
                        </div>
                      </div>
                      <div className="course-list-item">
                        <span className="icon is-medium  is-small eye-off">
                          {/* <Icon path={mdiEye} /> */}
                        </span>
                        <span className="icon is-medium  is-small">
                          {/* <Icon path={mdiMenuRight} /> */}
                        </span>
                        <div className="course-info">
                          <span className="course-code">100107</span>
                          <span className="course-name">Antropología</span>
                        </div>
                      </div>
                      <div className="course-list-item">
                        <span className="icon is-medium  eye-off">
                          {/* <Icon path={mdiEye} /> */}
                        </span>
                        <span className="icon is-medium ">
                          {/* <Icon path={mdiMenuRight} /> */}
                        </span>
                        <div className="course-info">
                          <span className="course-code">100107</span>
                          <span className="course-name">Física Mecánica</span>
                        </div>
                      </div>
                    </div>
                    <div className="add-course-btn is-inline-block-tablet is-block-mobile">
                      <button className="button is-link is-outlined is-medium is-fullwidth">
                        <span className="icon">{/* <Icon path={mdiPlus} /> */}</span>
                        &nbsp;&nbsp;Agregar asignatura
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column">
                <div className="availability">
                  <div className="header">Disponibilidad</div>
                  <div className="availability-content">
                    <table className="ava-table">
                      <thead>
                        <tr>
                          <th style={{ width: "100px" }} />
                          <th>L</th>
                          <th>M</th>
                          <th>M</th>
                          <th>J</th>
                          <th>V</th>
                          <th>S</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="ava-table-modulo">8:00 - 8:50</td>
                          <td />
                          <td />
                          <td className="cell-azul" />
                          <td className="cell-azul" />
                          <td />
                          <td />
                        </tr>
                        <tr>
                          <td className="ava-table-modulo">9:00 - 9:50</td>
                          <td />
                          <td />
                          <td className="cell-azul" />
                          <td className="cell-azul" />
                          <td />
                          <td />
                        </tr>
                        <tr>
                          <td className="ava-table-modulo">10:00 - 10:50</td>
                          <td className="cell-verde" />
                          <td className="cell-verde" />
                          <td className="cell-cross" />
                          <td className="cell-cross" />
                          <td className="cell-verde" />
                          <td className="cell-verde" />
                        </tr>
                        <tr>
                          <td className="ava-table-modulo">11:00 - 11:50</td>
                          <td className="cell-verde" />
                          <td className="cell-verde" />
                          <td className="cell-cross" />
                          <td className="cell-cross" />
                          <td className="cell-verde" />
                          <td className="cell-verde" />
                        </tr>
                        <tr>
                          <td className="ava-table-modulo">12:00 - 12:50</td>
                          <td />
                          <td />
                          <td className="cell-azul" />
                          <td className="cell-azul" />
                          <td />
                          <td />
                        </tr>
                        <tr>
                          <td className="ava-table-modulo">13:00 - 13:50</td>
                          <td />
                          <td />
                          <td className="cell-azul" />
                          <td className="cell-azul" />
                          <td />
                          <td />
                        </tr>
                        <tr>
                          <td className="ava-table-modulo">14:00 - 14:50</td>
                          <td />
                          <td />
                          <td className="cell-azul" />
                          <td className="cell-azul" />
                          <td />
                          <td />
                        </tr>
                        <tr>
                          <td className="ava-table-modulo">15:00 - 15:50</td>
                          <td />
                          <td />
                          <td className="cell-azul" />
                          <td className="cell-azul" />
                          <td />
                          <td />
                        </tr>
                        <tr>
                          <td className="ava-table-modulo">16:00 - 16:50</td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        <tr>
                          <td className="ava-table-modulo">17:00 - 17:50</td>
                          <td className="cell-azul" />
                          <td className="cell-azul" />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        <tr>
                          <td className="ava-table-modulo">18:00 - 18:50</td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                        <tr>
                          <td className="ava-table-modulo">19:00 - 19:50</td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Instructor
