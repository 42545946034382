import React, { useState } from "react"
import { Dropdown, Menu, Modal, Loader, Button } from "@foris/foris-ui"
import { Link, withRouter, RouteComponentProps } from "react-router-dom"
import gql from "graphql-tag"
import { withApollo } from "react-apollo"
import "../../../grid__css_poc/bootstrap-grid.min.css"
import "@foris/foris-ui/lib/styles.css"
import logo from "../../../assets/svg/logo_Darwined.svg"

const GET_DEMAND_ADJUSTMENT_REPORT = gql`
  mutation DEMAND_ADJUSTMENT($projectId: ID!, $type: ReportType!) {
    report(projectId: $projectId, type: $type) {
      type
      url
    }
  }
`

const DefaultLayout: React.FC<RouteComponentProps<{}> & any> = (props: any) => {
  const [modalFileConfirmation, setModalFileConfirmation] = useState(false)
  const [linkReadyToDownload, setLinkReadyToDownload] = useState(false)
  const [reportLink, setReportLink] = useState("#")

  //error
  const [reportResponseError, setReportResponseError] = useState(false)

  return (
    <>
      <div className="bootstrap-wrapper">
        <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3">
          <Menu size="large" stackable>
            <Menu.Item>
              <Link to="/forecast/demand-editor" style={{ lineHeight: "0" }}>
                <img
                  src={logo}
                  style={{ width: "150px", marginLeft: "-6px" }}
                  width={180}
                  height={30}
                  alt="DarwinEd"
                />
              </Link>
            </Menu.Item>
            <Menu.Menu position="right">
              <Dropdown item text="Descargables">
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <a
                      target="_blank"
                      href="https://s3-us-west-2.amazonaws.com/files.foris.cl/public/Instructivo+Editor+de+Demandas.pdf"
                    >
                      Manual de uso
                    </a>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <a
                      target="_blank"
                      href="https://darwined-demand-editor.s3-us-west-2.amazonaws.com/reports/Reporte+de+Proyecci%C3%B3n+2021-2.xlsx"
                    >
                      Reporte proyección 2021-2
                    </a>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <a
                      target="_blank"
                      href="https://darwined-demand-editor.s3-us-west-2.amazonaws.com/reports/Detalle+reporte+de+proyecci%C3%B3n+2021-2.xlsx"
                    >
                      Detalle reporte proyección 2021-2
                    </a>
                  </Dropdown.Item>

                  {props.context && (
                    <Dropdown.Item>
                      <a
                        onClick={(e: React.SyntheticEvent) => {
                          e.preventDefault()

                          setLinkReadyToDownload(false)
                          setReportLink("#")
                          setReportResponseError(false)

                          setModalFileConfirmation(true)
                          props.client
                            .mutate({
                              mutation: GET_DEMAND_ADJUSTMENT_REPORT,
                              variables: {
                                projectId: props.context.auth.project.id,
                                type: "DEMAND_ADJUSTMENT"
                              }
                            })
                            .then((response: any) => {
                              setLinkReadyToDownload(true)
                              setReportLink(response.data.report.url)
                            })
                            .catch((error: any) => {
                              console.log(error)
                              setReportResponseError(true)
                            })
                        }}
                      >
                        Estatus de ajuste DDA
                      </a>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {props.context && (
                <Dropdown
                  item
                  text={props.context.auth.username}
                  style={{ marginRight: "1.5em", textTransform: "capitalize" }}
                >
                  <Dropdown.Menu>
                    {props.context.auth.role.role === "admin" && (
                      <>
                        <Dropdown.Item as={Link} to="/forecast/demand-editor/projects">
                          Administrar proyectos
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to="/forecast/demand-editor">
                          Proyecto activo
                        </Dropdown.Item>
                      </>
                    )}
                    <Dropdown.Item
                      onClick={(e: React.SyntheticEvent) => {
                        e.preventDefault()
                        window.location.href = `/logout?redirect=${props.context.auth.logoutUrl}`
                      }}
                    >
                      <span>Salir</span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Menu.Menu>
          </Menu>
        </div>
        <div className="container" style={{ marginTop: "2em" }}>
          {props.children}
        </div>
      </div>
      <Modal size="small" open={modalFileConfirmation}>
        <Modal.Header>Estatus de ajuste DDA</Modal.Header>
        <Modal.Content>
          {!linkReadyToDownload && (
            <div style={{ textAlign: "center", height: "80px" }}>
              <Loader active size="small">
                Generando el reporte...
              </Loader>
            </div>
          )}

          {!reportResponseError && linkReadyToDownload && reportLink && (
            <>
              <p>El reporte se ha generado exitosamente.</p>
              <Button primary>
                <a href={reportLink} target="_blank" style={{ color: "white" }}>
                  Descargar reporte
                </a>
              </Button>
            </>
          )}

          {reportResponseError && <p>Ha ocurrido un error, inténtelo nuevamente.</p>}
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={(e: React.SyntheticEvent) => {
              setModalFileConfirmation(false)
            }}
          >
            Cerrar
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default withRouter(withApollo(DefaultLayout))
