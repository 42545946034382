import React, { Component, Props } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Progress } from "@foris/foris-ui"
import { Link } from "react-router-dom"

import styled from "styled-components"
import { ILevel } from "../models"

const ProgressWrapper = styled.div`
  margin: 5px 0;
  .ui.green.progress .bar,
  .ui.indeterminate.green.progress .bar::before,
  .ui.progress .green.bar {
    background-color: rgb(68, 157, 122);
  }

  .ui.progress {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  .ui.progress .bar {
    border-radius: 3px;
  }

  .ui.progress .bar > .progress {
    font-weight: 400;
    font-size: 0.75em;
  }
`
interface LevelRouteProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
}

class LevelItem extends Component<LevelRouteProps & ILevel> {
  render() {
    const {
      level,
      unit: { id, progress }
    }: ILevel = this.props

    return (
      <div className="col-md-2 col-sm">
        <Link
          to={`/forecast/demand-editor/unit/${id}`}
          style={{ display: "block", overflow: "hidden" }}
        >
          Semestre {level} <br />
          <ProgressWrapper>
            <Progress size="small" percent={Math.round(Number(progress) * 100)} color="green" />
          </ProgressWrapper>
        </Link>
      </div>
    )
  }
}

export default withRouter(LevelItem)
