import React, { Component } from "react"
import { withRouter, Link } from "react-router-dom"
import { graphql } from "react-apollo"
import { Table, Input, Checkbox, Button } from "@foris/foris-ui"
import styled from "styled-components"
import { EDIT_DEMAND } from "../queries"
import { GET_UNITS } from "../../dashboard/queries"

import { isNumber } from "../../../helpers"

import { Slide, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const TableWrapper = styled.div`
  margin-top: 1.1em;
  padding: 1em 0.5em;

  .ui.celled.table > tbody > tr > td,
  .ui.celled.table > tbody > tr > th,
  .ui.celled.table > tfoot > tr > th,
  .ui.celled.table > thead > tr > th,
  .ui.celled.table > tr > td,
  .ui.celled.table > tr > th {
    height: 56px;
  }
`

const InputWrapper = styled.div`
  & .ui.input > input {
    max-width: 70px;
    text-align: center;
    padding: 6px 0px;
    font-family: Roboto, "sans-serif";
    margin: 0px;
  }
`

type Demand = {
  adjusted: string | number
  checked: boolean
  hasError: boolean
}

type DemandValue = {
  [key: string]: Demand
}

interface TableState {
  isLoading: boolean
  demandsFormValues: DemandValue
  countOwnDemands: number
}

class TableComponent extends Component<any, TableState> {
  state = { isLoading: false, demandsFormValues: {} as DemandValue, countOwnDemands: 0 }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.project !== this.props.project) {
      const unit = nextProps.project.unit
      this.updateParentData(unit)
    }
  }

  componentDidMount() {
    console.log("context???", this.props.context)

    this.updateParentData(this.props.project.unit)

    let formValues: any = this.refreshDeamnds(this.props.project.unit.demands)
    this.setState({ demandsFormValues: formValues })

    let count = 0
    this.props.project.unit.demands.forEach((item: any) => {
      if (item.own) count++
    })
    this.setState({ countOwnDemands: count })
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.project.unit.demands !== this.props.project.unit.demands) {
      let formValues: any = this.refreshDeamnds(this.props.project.unit.demands)
      this.setState({ demandsFormValues: formValues })
    }
  }

  refreshDeamnds(demands: any) {
    let obj: any = {}
    demands.map((demand: any) => {
      obj[demand.id] = {
        adjusted: Number(demand.adjusted),
        checked: demand.checked
      }
    })
    return obj
  }

  updateParentData(unit: any) {
    //UNIT KIND
    this.props.updateUnitType(unit.kind)

    // TITLES
    if (unit.kind === "LEVEL") {
      this.props.updateTitle(
        unit.kind,
        `${unit.programName} | ${unit.curriculumName} | Nivel: ${unit.tags.level}`
      )
    } else if (unit.kind === "SUBJECT") {
      this.props.updateTitle(unit.kind, `${unit.tags.subject} ${unit.label}`)
    } else {
      this.props.updateTitle(unit.kind, unit.label)
    }

    // UNIT LINKS
    this.props.updateNavLinks(unit.previusUnit, unit.nextUnit)
  }

  changeAdjustedValue = (e: any, demandId: string) => {
    e.preventDefault()

    let hasError: boolean = !isNumber(e.target.value) ? true : false
    let currentValue: any = this.state.demandsFormValues[demandId]

    this.setState({
      demandsFormValues: {
        ...this.state.demandsFormValues,
        [demandId]: {
          ...currentValue,
          adjusted: e.target.value,
          hasError: hasError
        }
      }
    })
  }

  changeCheckedValue = (e: any, { value, demandid, checked }: any) => {
    const currentValue: any = this.state.demandsFormValues[demandid]
    this.setState({
      demandsFormValues: {
        ...this.state.demandsFormValues,
        [demandid]: {
          ...currentValue,
          checked: checked
        }
      }
    })
  }

  formIsValid(values: any) {
    let totalErrors = 0

    for (var i = 0, length = Object.keys(values).length; i < length; i++) {
      const currentKey = Object.keys(values)[i]

      if (!isNumber(values[currentKey].adjusted)) {
        const currentValue: any = this.state.demandsFormValues[currentKey]
        this.setState({
          demandsFormValues: {
            ...this.state.demandsFormValues,
            [currentKey]: {
              ...currentValue,
              hasError: true
            }
          }
        })
        totalErrors++
      }
    }

    return !totalErrors
  }

  handleSaveButton = (e: React.SyntheticEvent) => {
    e.preventDefault()

    const values: any = this.state.demandsFormValues
    const unitId: string = this.props.project.unit.id

    if (!this.formIsValid(values)) return

    this.setState({ isLoading: true })

    let filters: any = {
      departmentId: this.props.context.dashboard.currentDepartmentListSelected.value,
      programId:
        this.props.context.dashboard.currentProgramListSelected.value === "*"
          ? null
          : this.props.context.dashboard.currentProgramListSelected.value,
      unisabanaDepartmentId:
        this.props.context.dashboard.currentSchoolDepartmentListSelected.value === "*"
          ? null
          : this.props.context.dashboard.currentSchoolDepartmentListSelected.value
    }

    this.props
      .mutate({
        variables: {
          unitId: unitId,
          demandAdjustments: (() => {
            return Object.keys(values).map((key: string) => {
              return {
                demandId: key,
                adjusted: Number(values[key].adjusted),
                confirmed: Boolean(values[key].checked)
              }
            })
          })()
        },
        update: (cache: any, reponse: any) => {
          if (!filters.departmentId && !filters.programId && !filters.unisabanaDepartmentId) {
            return false
          } else {
            try {
              const cacheObj = cache.readQuery({
                query: GET_UNITS,
                variables: {
                  projectId: this.props.context.projectId,
                  filter: filters
                }
              })

              this.props.context.updateDashboardCurrentData("unitView", cacheObj.project.dashboard)
            } catch (e) {
              console.log("error: >", e)
            }
          }
        }
      })
      .then((data: any) => {
        toast.info("La demanda se ha actualizado correctamente", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          transition: Slide
        })
        this.setState({ isLoading: false })
      })
      .catch((error: any) => {
        this.setState({ isLoading: false })
      })
  }

  handleReturnButton = (e: React.SyntheticEvent) => {
    e.preventDefault()
    this.props.history.push("/forecast/demand-editor")
  }

  render() {
    const demands: any = this.props.project
    const currentValues: any = this.state.demandsFormValues
    return (
      <>
        <TableWrapper>
          <Table style={{ width: "100%", padding: "0 1em" }} basic="very" celled collapsing>
            <Table.Header>
              <Table.Row>
                {demands.unit.kind !== "LEVEL" && <Table.HeaderCell> Carrera</Table.HeaderCell>}
                {demands.unit.kind !== "LEVEL" && <Table.HeaderCell> Currículo</Table.HeaderCell>}
                <Table.HeaderCell>Código</Table.HeaderCell>
                <Table.HeaderCell>Alias</Table.HeaderCell>
                <Table.HeaderCell>Asignatura</Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>DDA Proyectada</Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>DDA Ajustada</Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: "center" }}>Confirmada</Table.HeaderCell>
                {demands.unit.kind !== "CROSSLIST" && (
                  <Table.HeaderCell style={{ textAlign: "center" }}>Lista Cruzada</Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {demands.unit.demands.map((demand: any) => (
                <Table.Row key={demand.id} className={!demand.own ? "disabled" : ""}>
                  {demands.unit.kind !== "LEVEL" && (
                    <Table.Cell style={{ width: "115px" }}>
                      {" "}
                      {demand.course.curriculum.program.name}
                    </Table.Cell>
                  )}
                  {demands.unit.kind !== "LEVEL" && (
                    <Table.Cell style={{ width: "120px" }}>
                      <Link to={demand.relatedUnits.level.id}>{demand.course.curriculum.name}</Link>
                    </Table.Cell>
                  )}
                  <Table.Cell style={{ width: "70px" }}> {demand.course.code} </Table.Cell>
                  <>
                    {demand.relatedUnits.subject.id ? (
                      <Table.Cell style={{ width: "100px", textAlign: "center" }}>
                        <Link to={demand.relatedUnits.subject.id}>{demand.course.label}</Link>
                      </Table.Cell>
                    ) : (
                      <Table.Cell />
                    )}
                  </>

                  <Table.Cell>
                    <span
                      style={{
                        maxWidth: "350px",
                        display: "block",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {demand.course.name}
                    </span>
                  </Table.Cell>
                  <Table.Cell style={{ width: "100px", textAlign: "center" }}>
                    {demand.value}
                  </Table.Cell>
                  <Table.Cell style={{ width: "100px", textAlign: "center" }}>
                    {currentValues[demand.id] && (
                      <>
                        {!demand.own || currentValues[demand.id].checked ? (
                          <>
                            {currentValues[demand.id].adjusted !== null
                              ? currentValues[demand.id].adjusted
                              : ""}
                          </>
                        ) : (
                          <InputWrapper>
                            <Input
                              error={currentValues[demand.id].hasError}
                              type="text"
                              pattern="[0-9]*"
                              onChange={(e: React.SyntheticEvent) =>
                                this.changeAdjustedValue(e, demand.id)
                              }
                              name={`adjusted_${demand.id}`}
                              defaultValue={
                                currentValues[demand.id].adjusted
                                  ? currentValues[demand.id].adjusted
                                  : ""
                              }
                              placeholder={`${currentValues[demand.id].adjusted}`}
                            />
                          </InputWrapper>
                        )}
                      </>
                    )}
                  </Table.Cell>
                  <Table.Cell style={{ width: "100px", textAlign: "center" }}>
                    <div className="field">
                      <Checkbox
                        demandid={demand.id}
                        onClick={this.changeCheckedValue}
                        name={`check_${demand.id}`}
                        defaultChecked={demand.checked}
                      />
                    </div>
                  </Table.Cell>

                  {demands.unit.kind !== "CROSSLIST" ? (
                    <>
                      {demand.relatedUnits.crosslist.id ? (
                        <Table.Cell style={{ width: "100px", textAlign: "center" }}>
                          <Link to={demand.relatedUnits.crosslist.id}>
                            {demand.relatedUnits.crosslist.code}
                          </Link>
                        </Table.Cell>
                      ) : (
                        <Table.Cell style={{ width: "100px", textAlign: "center" }}> </Table.Cell>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Table.Row>
              ))}
              {demands.unit.kind !== "LEVEL" && (
                <Table.Row>
                  <Table.Cell colSpan={5}>TOTAL</Table.Cell>
                  <Table.Cell style={{ textAlign: "center" }}>
                    {(() => {
                      let total: number = 0
                      demands.unit.demands.forEach((demand: any) => (total += demand.value))
                      return total
                    })()}
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: "center" }}>
                    {(() => {
                      let total: number = 0
                      Object.entries(currentValues).forEach(([key, value]: any) => {
                        return (total += Number(currentValues[key].adjusted))
                      })
                      return total
                    })()}
                  </Table.Cell>
                  <Table.Cell />
                  {demands.unit.kind === "SUBJECT" ? <Table.Cell /> : <></>}
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </TableWrapper>

        <br />

        <div style={{ padding: "1em 1em 2em 1em", textAlign: "right", marginBottom: "1.5em" }}>
          <Button
            onClick={(e: React.SyntheticEvent) => this.handleReturnButton(e)}
            style={{ marginRight: "1em" }}
          >
            Cancelar
          </Button>
          {this.state.countOwnDemands > 0 ? (
            <Button
              loading={this.state.isLoading}
              onClick={(e: React.SyntheticEvent) => this.handleSaveButton(e)}
              primary
            >
              Guardar
            </Button>
          ) : (
            <></>
          )}
        </div>
      </>
    )
  }
}

const componentWithRouter: any = withRouter(TableComponent)

export const TableWithMutation = graphql(EDIT_DEMAND)(componentWithRouter)
