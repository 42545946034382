import React from "react"
import { Query } from "react-apollo"
import { withForecastContext, ForecastContextInterface } from "../../../../context"
import ListPage from "./components/ListPage"
import { GET_PROJECTS_QUERY } from "./queries"
import { Loader } from "@foris/foris-ui"
import { BreadCrumb } from "../../../../../../common/components"

const breadCrumbItems = [{ content: "Proyectos", link: false }]

const Container = (props: any) => {
  return (
    <>
      <BreadCrumb items={breadCrumbItems} />
      {/* <h3 style={{ margin: "0px 0px 1.5em 0px", padding: "0px" }}>
        Test-41 <span style={{ fontWeight: 400 }}>(BASE / UPCH / Origen: 525)</span>
      </h3> */}
      {props.content}
    </>
  )
}

const ProjectListContainer = ({
  forecastContext
}: {
  forecastContext?: ForecastContextInterface
}) => forecastContext && <Container content={<ListPage context={forecastContext} />} />

export default withForecastContext(ProjectListContainer)
