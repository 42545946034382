import React, { Component, Props } from "react"
import { withApollo, Query } from "react-apollo"
import { Link, withRouter, RouteComponentProps } from "react-router-dom"
import styled from "styled-components"
import { Icon, Form, Button, Modal, Divider, Table } from "@foris/foris-ui"
import { SearchField } from "./index"
import { GET_DEPARTMENTS, GET_UNITS } from "../../dashboard/queries"
import { ADD_CROSSLIST_MUTATION } from "../queries"

import { Slide, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const TOAST_OPTIONS: object = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  transition: Slide
}

const TableWrapper = styled.div`
  .ui.table > tbody > tr > td,
  .ui.table > tr > td {
    padding: 0.8em 0.9em;
  }
  .ui[class*="very basic"].table:not(.sortable):not(.striped) > tbody > tr > td:last-child {
    padding-right: 0.9em;
  }
  .ui[class*="very basic"].table:not(.sortable):not(.striped) > tbody > tr > td:first-child {
    padding-left: 0.9em;
  }
`

const filterFactory = (array: object[]) => {
  return array.map((d: any) => ({
    value: d.id,
    id: d.id,
    text: d.name
  }))
}

interface FormCLAddRouteProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
}

class FormCLAdd extends Component<FormCLAddRouteProps & any, any> {
  state = {
    code: "",
    name: "",
    demands: {} as any,
    department: {} as any,
    isLoading: false,
    codeHasEmptyError: false,
    departmentHasEmptyError: false,
    showConfirmation: false,
    countDemandsWithCrossList: 0,
    codeHasIncorrectValue: false
  }

  handlecodeChange = (e: React.SyntheticEvent, { value }: any) => {
    let inputValue = value.toUpperCase()
    if (/^[A-Z\d._-]*$/.test(inputValue)) {
      this.setState({ codeHasIncorrectValue: false, codeHasEmptyError: false })
    }
    this.setState({ code: inputValue })
  }

  handleNameChange = (e: React.SyntheticEvent, { value }: any) => {
    this.setState({ name: value })
  }

  handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()

    if (this.state.code === "") {
      this.setState({ codeHasEmptyError: true })
      return
    }

    if (!/^[A-Z\d._-]*$/.test(this.state.code)) {
      this.setState({ codeHasIncorrectValue: true })
      return
    }

    if (!this.state.department.hasOwnProperty("value")) {
      this.setState({ departmentHasEmptyError: true })
      return
    }

    const demands = this.state.demands
    let demandsWithCrossList = 0
    Object.keys(this.state.demands).forEach((key: any) => {
      if (
        demands[key]["relatedUnits"] &&
        demands[key]["relatedUnits"]["crosslist"] &&
        demands[key]["relatedUnits"]["crosslist"]["code"]
      ) {
        demandsWithCrossList++
      }
    })

    if (demandsWithCrossList > 0) {
      this.setState({ countDemandsWithCrossList: demandsWithCrossList, showConfirmation: true })
    } else {
      this.sendForm()
    }
  }

  sendForm() {
    this.setState({ isLoading: true })
    let { code, demands, name }: any = this.state
    demands = this.demandsFactory(demands)

    let crossListAddObject = {
      input: {
        kind: "crosslist",
        label: code,
        description: name,
        projectId: this.props.projectId,
        departmentId: this.state.department.value,
        demands
      }
    }

    this.props.client
      .mutate({
        mutation: ADD_CROSSLIST_MUTATION,
        variables: crossListAddObject,
        update: (proxy: any, response: any) => {
          // if departmentId is null === || !currentProgramList --> 'not have cache for this query'
          if (
            this.props.dashboard.currentDepartmentListSelected.value ||
            this.props.dashboard.currentProgramListSelected.value ||
            this.props.dashboard.currentSchoolDepartmentListSelected.value
          ) {
            const departmentsToUpdate: any = []

            this.props.dashboard.currentProgramList.forEach((item: any) => {
              departmentsToUpdate.push(item.id === "*" ? null : item.id)
            })

            // update all program in current department
            departmentsToUpdate.forEach((programId: any) => {
              try {
                const data = proxy.readQuery({
                  query: GET_UNITS,
                  variables: {
                    projectId: this.props.projectId,
                    filter: {
                      departmentId: this.props.dashboard.currentDepartmentListSelected.value,
                      programId: programId,
                      unisabanaDepartmentId:
                        this.props.dashboard.currentSchoolDepartmentListSelected.value === "*"
                          ? null
                          : this.props.dashboard.currentSchoolDepartmentListSelected.value
                    }
                  }
                })

                console.log("data --->", data)

                if (data) {
                  data.project.dashboard.crosslistView.push(response.data.addUnit)

                  proxy.writeQuery({
                    query: GET_UNITS,
                    variables: {
                      projectId: this.props.projectId,
                      filter: {
                        departmentId: this.props.dashboard.currentDepartmentListSelected.value,
                        programId: programId,
                        unisabanaDepartmentId:
                          this.props.dashboard.currentSchoolDepartmentListSelected.value === "*"
                            ? null
                            : this.props.dashboard.currentSchoolDepartmentListSelected.value
                      }
                    },
                    data: data
                  })
                }
              } catch (e) {
                console.log(e)
              }
            })
          }
        }
      })
      .then((response: any) => {
        const status = response.data.addUnit.status

        if (status.code) {
          switch (status.code) {
            case 200:
              toast.info("La lista se ha creado correctamente", TOAST_OPTIONS)
              setTimeout(() => {
                this.props.history.push("/forecast/demand-editor/")
              }, 900)
              break
            case 409:
              if (status.msg === "CROSSLIST_LABEL_ALREADY_EXITS") {
                toast.warn("Ya existe una lista con este código", TOAST_OPTIONS)
              } else if (status.msg === "EMPTY_DEMAND") {
                toast.warn("No puedes crear una lista vacía", TOAST_OPTIONS)
              } else {
                toast.warn("Ocurrió un error, inténtalo nuevamente.", TOAST_OPTIONS)
              }
              break
          }
        }
        this.setState({ isLoading: false })
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  demandsFactory(demands: any) {
    let array: any[] = []

    Object.keys(demands).forEach((demandKey: any) => {
      array.push({
        id: demandKey,
        value: demands[demandKey].value,
        checked: demands[demandKey].checked,
        adjusted: demands[demandKey].adjusted
      })
    })

    return array
  }

  updateTotalValue = (): number => {
    let total: number = 0
    Object.entries(this.state.demands).forEach(([key, value]: any) => (total += value.value))
    return total
  }

  onSelectDemand = (demand: any) => {
    this.setState({
      demands: { ...this.state.demands, [demand.id]: demand }
    })
  }

  onRemoveDemand = (demandId: string) => {
    let demands = this.state.demands
    if (demands.hasOwnProperty(demandId)) delete demands[demandId]

    this.setState({ demands })
  }

  render() {
    const {
      code,
      name,
      demands,
      codeHasEmptyError,
      departmentHasEmptyError,
      showConfirmation,
      countDemandsWithCrossList,
      codeHasIncorrectValue
    } = this.state

    return (
      <div style={{ marginBottom: "2.5em", backgroundColor: "white", padding: "2em 2.5em" }}>
        <h3>Crear lista cruzada</h3>
        <br />
        <Form>
          <div className="row">
            <div className="col">
              <Form.Group>
                <Form.Field width={2}>
                  <Form.Input
                    name="code"
                    label="Código"
                    onChange={this.handlecodeChange}
                    placeholder="Código"
                    error={codeHasEmptyError || codeHasIncorrectValue}
                  />
                  {codeHasIncorrectValue && (
                    <div style={{ lineHeight: "14px", marginTop: "3px" }}>
                      <small style={{ color: "#ff3860", fontWeight: "bold", fontSize: "13.5px" }}>
                        El código debe contener sólo caracteres alfanuméricos, puntos y guiones
                      </small>
                    </div>
                  )}
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    label="Nombre"
                    name="name"
                    placeholder="Nombre"
                    onChange={this.handleNameChange}
                  />
                </Form.Field>
                <Form.Field width={5}>
                  <Query query={GET_DEPARTMENTS} variables={{ projectId: this.props.projectId }}>
                    {({ loading, error, data }: any) => {
                      if (loading) return <p>Loading...</p>
                      if (error) return <p>Error :(</p>
                      return (
                        <Form.Select
                          search
                          label="Escuela"
                          className="ellipsisText"
                          options={filterFactory(data.project.departments)}
                          defaultValue={this.state.department.value}
                          error={departmentHasEmptyError}
                          placeholder="Selecciona una escuela"
                          onChange={(e: React.SyntheticEvent, filterValue: any) => {
                            this.setState({
                              department: filterValue,
                              departmentHasEmptyError: false
                            })
                          }}
                        />
                      )
                    }}
                  </Query>
                </Form.Field>
              </Form.Group>
            </div>
          </div>
          <Divider section />
          <div className="row">
            <div className="col">
              <Form.Field width={5}>
                <label className="label">Asignaturas</label>
                <SearchField
                  projectId={this.props.projectId}
                  onSelectDemand={this.onSelectDemand}
                  excludeFromResults={this.state.demands}
                />
              </Form.Field>
            </div>
          </div>
        </Form>
        <div className="row" style={{ marginTop: "2.5em" }}>
          <div className="col">
            <TableWrapper>
              <Table style={{ width: "100%" }} basic="very" celled collapsing>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Carrera</Table.HeaderCell>
                    <Table.HeaderCell>Currículo</Table.HeaderCell>
                    <Table.HeaderCell>Código</Table.HeaderCell>
                    <Table.HeaderCell>Alias</Table.HeaderCell>
                    <Table.HeaderCell>Asignatura</Table.HeaderCell>
                    <Table.HeaderCell>Lista</Table.HeaderCell>
                    <Table.HeaderCell>Demanda</Table.HeaderCell>
                    <Table.HeaderCell />
                  </Table.Row>
                </Table.Header>
                {Object.keys(demands).length ? (
                  <Table.Body>
                    {Object.keys(demands).map((key: string) => {
                      if (demands[key]) {
                        return (
                          <Table.Row key={demands[key].id}>
                            {/* <tr key={demands[key].id}> */}
                            <Table.Cell>{demands[key].programName}</Table.Cell>
                            <Table.Cell>{demands[key].curriculumName}</Table.Cell>
                            <Table.Cell>{demands[key].course.code}</Table.Cell>
                            <Table.Cell>{demands[key].courseLabel}</Table.Cell>
                            <Table.Cell>{demands[key].course.code}</Table.Cell>
                            <Table.Cell style={{ textAlign: "center" }}>
                              {demands[key].relatedUnits && demands[key].relatedUnits.crosslist ? (
                                <Link
                                  to={`/forecast/demand-editor/unit/${
                                    demands[key].relatedUnits.crosslist.id
                                  }`}
                                  target="_blank"
                                >
                                  {demands[key].relatedUnits.crosslist.code}
                                </Link>
                              ) : (
                                <></>
                              )}
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: "center" }}>
                              {demands[key].adjusted}
                            </Table.Cell>
                            <Table.Cell>
                              <div className="buttons">
                                <a
                                  className="button"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e: React.SyntheticEvent) => {
                                    this.onRemoveDemand(demands[key].id)
                                  }}
                                >
                                  <Icon name="trash alternate" />
                                </a>
                              </div>
                            </Table.Cell>
                          </Table.Row>
                        )
                      } else {
                        return <></>
                      }
                    })}
                    <Table.Row style={{ backgroundColor: "#f1efef" }}>
                      <Table.Cell colSpan={6}>TOTAL</Table.Cell>
                      <Table.Cell style={{ textAlign: "center" }}>
                        {this.updateTotalValue()}
                      </Table.Cell>
                      <Table.Cell />
                    </Table.Row>
                  </Table.Body>
                ) : (
                  <Table.Body />
                )}
              </Table>
            </TableWrapper>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div style={{ padding: "2em 0 1em 0", textAlign: "right" }}>
              <Link
                to="/forecast/demand-editor"
                style={{ display: "inline-block", marginRight: "1em" }}
              >
                <Button>Cancelar</Button>
              </Link>
              <Button
                primary
                loading={this.state.isLoading}
                onClick={(e: React.SyntheticEvent) => this.handleSubmit(e)}
              >
                Crear lista cruzada
              </Button>
            </div>
          </div>
        </div>

        {/* MODAL */}

        <Modal size="small" open={showConfirmation}>
          {/* showConfirmation */}
          <Modal.Header>Confirmar cambio</Modal.Header>
          <Modal.Content>
            <p>
              {countDemandsWithCrossList > 1
                ? `Con esta acción se cambiarán ${countDemandsWithCrossList} asignaturas desde otras listas cruzadas a la presente.`
                : `Con esta acción se cambiará ${countDemandsWithCrossList} asignatura desde otra lista cruzada a la presente.`}
            </p>
            <p className="is-pulled-right">¿Desea continuar?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={(e: React.SyntheticEvent) => {
                this.setState({ showConfirmation: false })
              }}
            >
              Cancelar
            </Button>
            <Button
              primary
              content="Confirmar"
              onClick={(e: React.SyntheticEvent) => {
                this.setState({ showConfirmation: false })
                this.sendForm()
              }}
            />
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default withRouter(withApollo(FormCLAdd))
