import React, { Component } from "react"
import {
  BrowserRouter,
  Route,
  Switch,
  withRouter,
  RouteComponentProps,
  Link
} from "react-router-dom"
import { Subscription } from "react-apollo"

import gql from "graphql-tag"
import { moduleRoutes } from "./routes"
import DefaultLayout from "../../common/components/layout/DefaultLayout"
import { AuthConsumer } from "../../common/contexts/AuthContext"
import { ForecastContextProvider } from "./context"
import { Message } from "@foris/foris-ui"
import AddProjectPageContainer from "./modules/demand-editor/pages/project/AddProjectPageContainer"
import ProjectListContainer from "./modules/demand-editor/pages/project/ProjectListContainer"
import EditProjectPageContainer from "./modules/demand-editor/pages/project/EditProjectPageContainer"
import ProjectStatus from "./modules/demand-editor/pages/project-status"

import { ToastContainer, Slide, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export const TOAST_OPTIONS: object = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  transition: Slide
}

const PROJECT_SUSCRIPTION = gql`
  subscription onProjectStateChange {
    migratedProject {
      id
      active
      state
      description
    }
  }
`

class Forecast extends Component<RouteComponentProps<{}>> {
  state = {
    value: {
      updateDashboardCurrentData: {} as any,
      dashboard: {} as any,
      currentProject: JSON.parse(localStorage.getItem("__darwined_user_data") || "")
    }
  }

  componentDidMount() {
    this.setState({
      value: {
        updateDashboardCurrentData: this.updateDashboard,
        dashboard: {
          filterSearchState: "PRISTINE",
          currentDepartmentList: [],
          currentDepartmentListSelected: { text: null, value: null, key: null },
          currentProgramList: [],
          currentProgramListSelected: { text: null, value: null, key: null },
          currentSchoolDepartmentList: [],
          currentSchoolDepartmentListSelected: { text: null, value: null, key: null },
          selectedTab: "curriculum",
          unitView: {
            levelView: null,
            subjectView: null,
            crosslistView: null
          },
          currentUnitTitle: null,
          loadingSearch: false
        },
        currentProject: this.state.value.currentProject.project
          ? this.state.value.currentProject.project
          : null,
        updateCurrentProject: this.updateCurrentProject
      }
    })
  }

  updateCurrentProject = (project: any) => {
    if (this.state.value.currentProject !== project) {
      this.setState({ ...this.state, value: { ...this.state.value, currentProject: project } })
    }
  }

  updateDashboard = (type: string, newValue: any) => {
    if (this.state.value.dashboard[type] !== newValue) {
      switch (type) {
        case "selectedTab":
        case "unitView":
        case "currentProgramListSelected":
        case "currentSchoolDepartmentListSelected":
        case "currentProgramList":
        case "currentUnitTitle":
        case "loadingSearch":
        case "filterSearchState":
          this.setState({
            value: {
              ...this.state.value,
              dashboard: {
                ...this.state.value.dashboard,
                [type]: newValue
              }
            }
          })
          break
        case "currentDepartmentListSelected":
          this.setState({
            value: {
              ...this.state.value,
              dashboard: {
                ...this.state.value.dashboard,
                currentDepartmentListSelected: newValue["selectedValue"],
                currentProgramList: newValue["newProgramList"],
                currentProgramListSelected: { text: "Todas las carreras", value: "*", key: "*" }
              }
            }
          })
          break
      }
    }
  }

  render() {
    const { currentProject } = this.state.value
    return (
      <AuthConsumer>
        {(authContext: any) => (
          <BrowserRouter>
            <DefaultLayout context={authContext}>
              <ToastContainer />
              <ForecastContextProvider
                value={{
                  ...this.state.value,
                  projectId: currentProject && currentProject.id ? currentProject.id : "",
                  auth: authContext.auth
                }}
              >
                {authContext.auth &&
                authContext.auth.token &&
                currentProject &&
                currentProject.id ? (
                  <>
                    <Subscription
                      subscription={PROJECT_SUSCRIPTION}
                      variables={{ projectId: currentProject.id }}
                      onSubscriptionData={(response: any) => {
                        const data = response.subscriptionData.data

                        // PROJECT SUBSCRIPTION ---->

                        if (data && data.migratedProject) {
                          let project = {
                            id: data.migratedProject.id,
                            active: data.migratedProject.active,
                            state: data.migratedProject.state,
                            description: data.migratedProject.description
                          }

                          const currentStorage = JSON.parse(localStorage.__darwined_user_data)
                          const prevStateName = "" + currentStorage.project.state

                          if (project !== currentStorage.project) {
                            currentStorage["project"] = project
                            localStorage.setItem(
                              "__darwined_user_data",
                              JSON.stringify(currentStorage)
                            )

                            const confirmationMsg: any = {
                              EXPORT: "El proyecto ha sido exportado correctamente",
                              SYNC: "El proyecto ha sido sincronizado correctamente",
                              MIGRATE: "El proyecto ha sido creado correctamente",
                              BLOCK: "El proyecto ha sido creado correctamente"
                            }

                            if (prevStateName === "EXPORT") {
                              window.location.href = "/forecast/demand-editor"
                            }

                            if (prevStateName === "SYNC") {
                              window.location.href = "/forecast/demand-editor"
                            }

                            if (prevStateName === "MIGRATE") {
                              window.location.href = "/forecast/demand-editor"
                            }

                            if (prevStateName === "BLOCK") {
                              window.location.href = "/forecast/demand-editor"
                            }

                            this.forceUpdate()
                            this.updateCurrentProject(project)
                            toast.info(confirmationMsg[prevStateName], TOAST_OPTIONS)
                          }
                        }

                        // <---- PROJECT SUBSCRIPTION
                      }}
                    >
                      {({ data, loading }: any) => {
                        return (
                          <>
                            {currentProject && (currentProject.id && currentProject.active) ? (
                              <Switch>
                                {currentProject.state === "READY" ? (
                                  moduleRoutes.map((route: any) => {
                                    if (route.permissions === "public") {
                                      return (
                                        <Route
                                          exact
                                          key={route.path}
                                          path={route.path}
                                          component={route.component}
                                        />
                                      )
                                    } else if (
                                      authContext.auth.role.role === "admin"
                                      //  ( route.name.includes("cleditor") &&
                                      //   authContext.auth.role.permissions.crosslist.includes("create") )
                                    ) {
                                      return (
                                        <Route
                                          exact
                                          key={route.path}
                                          path={route.path}
                                          component={route.component}
                                        />
                                      )
                                    }
                                  })
                                ) : (
                                  <ProjectStatus />
                                )}
                              </Switch>
                            ) : (
                              <>
                                {authContext.auth.role.role === "admin" ? (
                                  <>
                                    {/* // Provider only with project route */}
                                    <Switch>
                                      <Route
                                        exact={true}
                                        path="/forecast/demand-editor/projects"
                                        component={ProjectListContainer}
                                      />
                                      <Route
                                        exact={true}
                                        path="/forecast/demand-editor/project/edit/:projectId"
                                        component={EditProjectPageContainer}
                                      />
                                      <Route
                                        exact={true}
                                        path="/forecast/demand-editor/project/add"
                                        component={AddProjectPageContainer}
                                      />
                                      <Route
                                        exact={true}
                                        path="/forecast/demand-editor/project-status"
                                        component={ProjectStatus}
                                      />
                                      <Route
                                        path="*"
                                        exact={true}
                                        component={() => (
                                          <Message>
                                            <Message.Header>
                                              No hay un proyecto activo actualmente
                                            </Message.Header>
                                            <p>
                                              Puedes dirigirte a la sección{" "}
                                              <Link to="/forecast/demand-editor/projects">
                                                <strong>proyectos</strong>
                                              </Link>
                                            </p>
                                          </Message>
                                        )}
                                      />
                                    </Switch>
                                  </>
                                ) : (
                                  <Message>
                                    <Message.Header>
                                      No hay un proyecto activo actualmente
                                    </Message.Header>
                                    <p>Contacte al administrador del sistema</p>
                                  </Message>
                                )}
                              </>
                            )}
                          </>
                        )
                      }}
                    </Subscription>
                  </>
                ) : (
                  <></>
                )}
              </ForecastContextProvider>
            </DefaultLayout>
          </BrowserRouter>
        )}
      </AuthConsumer>
    )
  }
}

export default withRouter(Forecast)
