import React, { Component, Props } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import fakeAuth from "../../authClient"

interface LoginRouteProps extends RouteComponentProps<{}>, Props<{}> {
  history: any
}

class Login extends Component<LoginRouteProps> {
  componentDidMount() {
    fakeAuth.signOut((response: boolean) => {
      response && console.log(" ok ~")
    })
    if (fakeAuth.isAuthenticated()) this.props.history.push("/")
    fakeAuth.signIn((isValid: boolean) => {
      if (isValid) this.props.history.push("/")
    })
  }

  render() {
    return <p style={{ textAlign: "center", paddingTop: "2em" }}>Debes iniciar sesión</p>
  }
}

export default withRouter(Login)
